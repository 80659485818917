// ** React Imports
import { ReactNode } from 'react'
import { useFlag } from '@unleash/proxy-client-react'

// ** Mui Imports
import { Box } from '@mui/material'

// ** Source code Imports
import Header from './Header'
import Footer from './Footer'
import { socials, pages, menuList } from './opts'

interface IProps {
  sx?: { [key: string]: any }
  children: ReactNode
}

const DefaultLayout = ({ sx, children }: IProps) => {
  const isLandingNewsAvailable = useFlag('is_landing_news_available')

  const visiblePages = isLandingNewsAvailable ? pages : pages.filter(page => page.title !== 'news')

  return (
    <Box sx={{ background: 'black', overflowX: 'hidden', ...sx }}>
      <Header pages={visiblePages} />
      {children}
      <Footer socials={socials} pages={visiblePages} menuList={menuList} />
    </Box>
  )
}

export default DefaultLayout
