import { ReactNode } from 'react'

export type SocialItem = {
  id: string
  svg: ReactNode
  link: string
}

export type PageItem = {
  id: number
  title: string
  link: string
}

export type MenuItem = {
  id: number
  text: string
  link: string
  target: '_self' | '_blank'
}

export const socials = [
  // {
  //   id: 'YouTube',
  //   svg: (
  //     <svg width='41' height='29' viewBox='0 0 41 29' fill='none' xmlns='http://www.w3.org/2000/svg'>
  //       <path
  //         fillRule='evenodd'
  //         clipRule='evenodd'
  //         d='M36.5179 0.866181C38.283 1.3436 39.6703 2.74518 40.1427 4.5287C40.9966 7.75811 41 14.5 41 14.5C41 14.5 41 21.2419 40.1427 24.4714C39.6703 26.2549 38.283 27.6564 36.5179 28.1338C33.3218 29 20.4999 29 20.4999 29C20.4999 29 7.67821 29 4.48204 28.1338C2.71691 27.6564 1.32977 26.2549 0.85726 24.4714C0 21.2419 0 14.5 0 14.5C0 14.5 0 7.75811 0.85726 4.5287C1.32977 2.74518 2.71691 1.3436 4.48204 0.866181C7.67821 0 20.4999 0 20.4999 0C20.4999 0 33.3218 0 36.5179 0.866181ZM27.0478 14.5007L16.3961 20.714V8.28735L27.0478 14.5007Z'
  //         fill='#909090'
  //       />
  //       <path
  //         fillRule='evenodd'
  //         clipRule='evenodd'
  //         d='M36.5179 0.866181C38.283 1.3436 39.6703 2.74518 40.1427 4.5287C40.9966 7.75811 41 14.5 41 14.5C41 14.5 41 21.2419 40.1427 24.4714C39.6703 26.2549 38.283 27.6564 36.5179 28.1338C33.3218 29 20.4999 29 20.4999 29C20.4999 29 7.67821 29 4.48204 28.1338C2.71691 27.6564 1.32977 26.2549 0.85726 24.4714C0 21.2419 0 14.5 0 14.5C0 14.5 0 7.75811 0.85726 4.5287C1.32977 2.74518 2.71691 1.3436 4.48204 0.866181C7.67821 0 20.4999 0 20.4999 0C20.4999 0 33.3218 0 36.5179 0.866181ZM27.0478 14.5007L16.3961 20.714V8.28735L27.0478 14.5007Z'
  //         fill='black'
  //         fillOpacity='0.2'
  //       />
  //     </svg>
  //   ),
  //   link: 'https://www.youtube.com/@SILKmuz'
  // },
  {
    id: 'VK',
    svg: (
      <svg width='28' height='29' viewBox='0 0 28 29' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M1.96824 2.03853C0 4.07706 0 7.35797 0 13.92V15.08C0 21.642 0 24.9229 1.96824 26.9615C3.93647 29 7.10429 29 13.44 29H14.56C20.8957 29 24.0635 29 26.0318 26.9615C28 24.9229 28 21.642 28 15.08V13.92C28 7.35797 28 4.07706 26.0318 2.03853C24.0635 0 20.8957 0 14.56 0H13.44C7.10429 0 3.93647 0 1.96824 2.03853ZM4.66668 8.63097C4.81827 16.3941 8.45593 21.0595 14.8336 21.0595H15.1952V16.6182C17.5387 16.8669 19.3108 18.6958 20.0219 21.0595H23.3334C22.4239 17.5264 20.0336 15.573 18.5412 14.8266C20.0336 13.906 22.1323 11.6666 22.6337 8.63097H19.6255C18.9725 11.0943 17.0373 13.3337 15.1952 13.5453V8.63097H12.1869V17.2402C10.3214 16.7424 7.9663 14.329 7.86137 8.63097H4.66668Z'
          fill='#909090'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M1.96824 2.03853C0 4.07706 0 7.35797 0 13.92V15.08C0 21.642 0 24.9229 1.96824 26.9615C3.93647 29 7.10429 29 13.44 29H14.56C20.8957 29 24.0635 29 26.0318 26.9615C28 24.9229 28 21.642 28 15.08V13.92C28 7.35797 28 4.07706 26.0318 2.03853C24.0635 0 20.8957 0 14.56 0H13.44C7.10429 0 3.93647 0 1.96824 2.03853ZM4.66668 8.63097C4.81827 16.3941 8.45593 21.0595 14.8336 21.0595H15.1952V16.6182C17.5387 16.8669 19.3108 18.6958 20.0219 21.0595H23.3334C22.4239 17.5264 20.0336 15.573 18.5412 14.8266C20.0336 13.906 22.1323 11.6666 22.6337 8.63097H19.6255C18.9725 11.0943 17.0373 13.3337 15.1952 13.5453V8.63097H12.1869V17.2402C10.3214 16.7424 7.9663 14.329 7.86137 8.63097H4.66668Z'
          fill='black'
          fillOpacity='0.2'
        />
      </svg>
    ),
    link: 'https://vk.com/gosilk_ru'
  },
  {
    id: 'Telegram',
    svg: (
      <svg width='29' height='29' viewBox='0 0 29 29' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M29 14.5C29 22.5081 22.5081 29 14.5 29C6.49187 29 0 22.5081 0 14.5C0 6.49187 6.49187 0 14.5 0C22.5081 0 29 6.49187 29 14.5ZM9.41507 13.0865C7.9556 13.7187 6.45601 14.3683 5.08477 15.1177C4.36875 15.6378 5.3204 16.0058 6.21318 16.3508C6.3551 16.4057 6.49555 16.46 6.62757 16.5142C6.73743 16.5477 6.84915 16.5831 6.96235 16.6187C7.9553 16.9327 9.0625 17.2827 10.0265 16.7562C11.61 15.8537 13.1044 14.8103 14.5977 13.7678C15.087 13.4261 15.576 13.0847 16.0682 12.7483C16.0912 12.7336 16.1172 12.7168 16.1456 12.6985C16.5649 12.429 17.5076 11.8226 17.1589 12.6581C16.3343 13.5529 15.4509 14.3448 14.5629 15.1412C13.9644 15.6778 13.3636 16.2165 12.7773 16.7898C12.2664 17.2016 11.7362 18.0296 12.308 18.6061C13.6252 19.5209 14.9631 20.4137 16.3002 21.3059C16.7352 21.5962 17.1702 21.8864 17.6045 22.1775C18.3403 22.7604 19.4903 22.2888 19.6521 21.3779C19.7241 20.959 19.7964 20.54 19.8686 20.1209C20.2678 17.8054 20.6671 15.489 21.0199 13.166C21.0678 12.8016 21.1221 12.4372 21.1765 12.0727C21.3083 11.1891 21.4403 10.3044 21.4815 9.41607C21.3752 8.52957 20.2919 8.72449 19.6888 8.92382C16.5897 10.0938 13.5215 11.3505 10.4655 12.6294C10.1193 12.7815 9.76834 12.9336 9.41507 13.0865Z'
          fill='#909090'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M29 14.5C29 22.5081 22.5081 29 14.5 29C6.49187 29 0 22.5081 0 14.5C0 6.49187 6.49187 0 14.5 0C22.5081 0 29 6.49187 29 14.5ZM9.41507 13.0865C7.9556 13.7187 6.45601 14.3683 5.08477 15.1177C4.36875 15.6378 5.3204 16.0058 6.21318 16.3508C6.3551 16.4057 6.49555 16.46 6.62757 16.5142C6.73743 16.5477 6.84915 16.5831 6.96235 16.6187C7.9553 16.9327 9.0625 17.2827 10.0265 16.7562C11.61 15.8537 13.1044 14.8103 14.5977 13.7678C15.087 13.4261 15.576 13.0847 16.0682 12.7483C16.0912 12.7336 16.1172 12.7168 16.1456 12.6985C16.5649 12.429 17.5076 11.8226 17.1589 12.6581C16.3343 13.5529 15.4509 14.3448 14.5629 15.1412C13.9644 15.6778 13.3636 16.2165 12.7773 16.7898C12.2664 17.2016 11.7362 18.0296 12.308 18.6061C13.6252 19.5209 14.9631 20.4137 16.3002 21.3059C16.7352 21.5962 17.1702 21.8864 17.6045 22.1775C18.3403 22.7604 19.4903 22.2888 19.6521 21.3779C19.7241 20.959 19.7964 20.54 19.8686 20.1209C20.2678 17.8054 20.6671 15.489 21.0199 13.166C21.0678 12.8016 21.1221 12.4372 21.1765 12.0727C21.3083 11.1891 21.4403 10.3044 21.4815 9.41607C21.3752 8.52957 20.2919 8.72449 19.6888 8.92382C16.5897 10.0938 13.5215 11.3505 10.4655 12.6294C10.1193 12.7815 9.76834 12.9336 9.41507 13.0865Z'
          fill='black'
          fillOpacity='0.2'
        />
      </svg>
    ),
    link: 'https://t.me/gosilkru'
  }
] as SocialItem[]

export const pages = [
  {
    id: 1,
    title: 'main',
    link: '/'
  },
  {
    id: 2,
    title: 'artists',
    link: '/#artists'
  },
  {
    id: 3,
    title: 'how-work',
    link: '/#services'
  },
  {
    id: 4,
    title: 'sites',
    link: '/platforms'
  },
  {
    id: 5,
    title: 'news',
    link: '/news'
  },

  //
  // {
  //   id: 6,
  //   title: 'reviews',
  //   link: '#reviews'
  // },
  // {
  //   id: 7,
  //   title: 'partners',
  //   link: '#partners'
  // },
  {
    id: 8,
    title: 'faq',
    link: '/#faq'
  }
] as PageItem[]

export const menuList = [
  {
    id: 1,
    text: 'cookies-policy',
    link: '/cookies-policy'
  },
  {
    id: 2,
    text: 'privacy-policy',
    link: '/privacy-policy'
  }
] as MenuItem[]
