// ** MUI Imports
import { Theme } from '@mui/material/styles'

const Tooltip = (theme: Theme) => {
  return {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          paddingLeft: theme.spacing(4),
          paddingRight: theme.spacing(4),
          paddingTop: theme.spacing(4),
          paddingBottom: theme.spacing(6),
          backgroundColor: '#3A3E5B',
          borderRadius: theme.spacing(2),
          fontSize: 14,
          color: theme.palette.common.white,
          marginLeft: theme.spacing(-2)
        },
        arrow: {
          marginLeft: theme.spacing(1),
          color: '#3A3E5B'
        }
      }
    }
  }
}

export default Tooltip
