import { IConfig } from '@unleash/proxy-client-react'

const config: IConfig = {
  url: `${process.env.NEXT_PUBLIC_FEATURE_FLAG_API_URL}/${process.env.NEXT_PUBLIC_FEATURE_FLAG_API_ENV}`,
  clientKey: process.env.NEXT_PUBLIC_FEATURE_FLAG_API_KEY || '',
  environment: process.env.NEXT_PUBLIC_FEATURE_FLAG_API_ENV,
  refreshInterval: 15,
  appName: process.env.NEXT_PUBLIC_FEATURE_FLAG_APP_NAME || '',
}

export default config
