// ** React Imports
import { createContext, useEffect, useState, ReactNode } from 'react'

// ** Next Import
import { useRouter } from 'next/router'

// ** Defaults
const defaultProvider: string[] = []

const HistoryContext = createContext(defaultProvider)

type Props = {
  children: ReactNode
}

const HistoryProvider = ({ children }: Props) => {
  // ** States
  const [history, setHistory] = useState<string[]>([])

  // ** Hooks
  const router = useRouter()

  useEffect(() => {
    setHistory(state => [...state, router.pathname])
  }, [router])

  return <HistoryContext.Provider value={history}>{children}</HistoryContext.Provider>
}

export { HistoryContext, HistoryProvider }
