// ** Next Imports
import Link from 'next/link'

// ** React Imports
import * as React from 'react'

// ** Source code Imports
import appConfig from 'src/configs/app'

const { appUrl } = appConfig

// ** MUI Imports
import { AppBar, Box, Toolbar, IconButton, Container, Button, Stack, Dialog, DialogContent } from '@mui/material'

import MenuIcon from '@mui/icons-material/Menu'

// ** Source code Imports
import MenuList from 'src/layouts/components/menu-list/MenuList'
import MobileMenuList from 'src/layouts/components/menu-list/MobileMenuList'

// ** Hooks Imports
import { useIntl } from 'react-intl'
import { useState } from 'react'

// ** Source code Imports
import { PageItem } from './opts'

interface IProps {
  pages: PageItem[]
}

const Header = ({ pages }: IProps) => {
  // ** Hooks
  const { formatMessage } = useIntl()
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <AppBar
      position='fixed'
      sx={{
        backgroundColor: 'black',
        height: '80px',
        zIndex: 10
      }}
    >
      <Container
        sx={{
          '&.MuiContainer-root': { maxWidth: '1222px' },
          height: '100%',
          '@media (max-width:1024px)': {
            '&.MuiContainer-root': { pl: 4, pr: 4 }
          }
        }}
      >
        <Toolbar
          disableGutters
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            height: '100%',
            '@media (max-width:1024px)': {
              '&.MuiToolbar-root': { justifyContent: 'unset' }
            }
          }}
        >
          <IconButton
            size='large'
            edge='start'
            color='inherit'
            aria-label='menu'
            onClick={handleClickOpen}
            sx={{
              mr: 2,
              display: 'none',
              '@media (max-width:1024px)': { display: 'flex' }
            }}
          >
            <MenuIcon />
          </IconButton>
          <Dialog open={open} onClose={handleClose}>
            <DialogContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                background: 'black',
                p: '19px 16px 40px',
                position: 'fixed',
                top: 0,
                right: 0,
                width: 1,
                height: 1
              }}
            >
              <Box
                onClick={handleClose}
                sx={{
                  width: '32px',
                  height: '32px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'absolute',
                  top: '19px',
                  right: '16px'
                }}
              >
                <Box
                  sx={{
                    width: '24px',
                    height: '3px',
                    background: '#909090',
                    transform: 'rotate(-45deg)',
                    position: 'absolute'
                  }}
                ></Box>
                <Box
                  sx={{
                    width: '24px',
                    height: '3px',
                    background: '#909090',
                    transform: 'rotate(45deg)',
                    position: 'absolute'
                  }}
                ></Box>
              </Box>
              <Box>
                <IconButton
                  sx={{
                    p: 0,
                    mb: '100px'
                  }}
                >
                  <img src='/images/logo.svg' alt='logo' />
                </IconButton>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '20px',
                  fontSize: '17px'
                }}
              >
                <MobileMenuList pages={pages} handleClose={handleClose} />
              </Box>
              <Stack direction='column-reverse' spacing={6} sx={{ mt: 'auto' }}>
                <Button
                  component={Link}
                  href={`${appUrl}/register`}
                  variant='outlined'
                  sx={{
                    color: 'white',
                    borderRadius: '0',
                    border: '0.5px solid #FFFFFF',
                    padding: '11px 12px',
                    fontSize: '16px',
                    lineHeight: '19px'
                  }}
                >
                  {formatMessage({ id: 'sign-up' })}
                </Button>
                <Button
                  component={Link}
                  href={`${appUrl}/login`}
                  variant='contained'
                  sx={{
                    background: '#9000FF',
                    borderRadius: '0',
                    padding: '11px 17px',
                    fontSize: '16px',
                    lineHeight: '19px'
                  }}
                >
                  {formatMessage({ id: 'login' })}
                </Button>
              </Stack>
              <Box
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  width: 1,
                  zIndex: -1,
                  '&.MuiBox-root img': { width: 1 }
                }}
              >
                <img src='/images/mobille-menu/mobile-menu-bg.svg' alt='' />
              </Box>
            </DialogContent>
          </Dialog>
          <Box
            sx={{
              mr: 'auto',
              '@media (min-width:1025px)': {
                '&.MuiBox-root': { mr: 'unset' }
              }
            }}
          >
            <IconButton
              component={Link}
              href='/'
              sx={{
                p: 0,
                '&.MuiButtonBase-root': {
                  borderRadius: 'unset'
                },
                '&.MuiButtonBase-root:hover': {
                  backgroundColor: 'transparent'
                }
              }}
            >
              <img src='/images/logo.svg' alt='logo' />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: 'flex',
              fontSize: '14px',
              '& .MuiBox-root': {
                marginRight: '30px'
              },
              '& .MuiBox-root:last-child': {
                marginRight: '0px'
              }
            }}
          >
            <MenuList pages={pages} />
          </Box>
          <Stack direction='row' spacing={6}>
            <Button
              component={Link}
              href={`${appUrl}/register`}
              variant='outlined'
              sx={{
                color: 'white',
                borderRadius: '0',
                border: '0.5px solid #FFFFFF',
                padding: '11px 12px',
                fontSize: '16px',
                lineHeight: '19px',
                '&.MuiButtonBase-root:hover': {
                  background: '#9000FF',
                  color: '#fff'
                },
                '@media (max-width: 768px)': { display: 'none' }
              }}
            >
              {formatMessage({ id: 'sign-up' })}
            </Button>
            <Button
              component={Link}
              href={`${appUrl}/login`}
              variant='contained'
              sx={{
                background: '#9000FF',
                borderRadius: '0',
                padding: '11px 17px',
                fontSize: '16px',
                lineHeight: '19px',
                '&.MuiButtonBase-root:hover': {
                  background: '#fff',
                  color: '#9000FF'
                }
              }}
            >
              {formatMessage({ id: 'login' })}
            </Button>
          </Stack>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
export default Header
