// ** React Imports
import { useMemo } from 'react'

// ** Next Imports
import Link from 'next/link'
import { useRouter } from 'next/router'

// ** MUI Imports
import { Box, Container, IconButton, Typography, List } from '@mui/material'

// ** Hooks Imports
import { useIntl } from 'react-intl'

// ** Source code Imports
import FooterBottomList from 'src/layouts/components/footer-bottom-list/FooterBottomList'
import { SocialItem, PageItem, MenuItem } from './opts'

interface IProps {
  socials: SocialItem[]
  pages: PageItem[]
  menuList: MenuItem[]
}

const Footer = ({ socials, pages, menuList }: IProps) => {
  // ** Hooks
  const { formatMessage } = useIntl()
  const router = useRouter()
  const hash = router.asPath.split('#')[1]

  const currentYear = useMemo(() => new Date().getFullYear(), [])

  return (
    <Box
      sx={{
        pb: '120px',
        color: '#5c5c5c',
        '@media (max-width:768px)': {
          pb: '70px'
        }
      }}
    >
      <Container
        sx={{
          '&.MuiContainer-root': {
            maxWidth: '1222px'
          },
          '@media (max-width:1024px)': {
            pl: 4,
            pr: 4
          }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: '1px solid #5c5c5c',
            pb: '32px',
            '@media (max-width:900px)': {
              flexDirection: 'column-reverse',
              alignItems: 'flex-start'
            }
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '4px',
              '& .MuiButtonBase-root:hover': {
                backgroundColor: 'transparent'
              },
              '@media (max-width:900px)': {
                '&.MuiBox-root': {
                  mt: '36px'
                }
              }
            }}
          >
            <Box
              sx={{
                display: 'contents'
              }}
              component={Link}
              href='/'
            >
              <img src='/images/logo.svg' alt='logo' />
            </Box>

            {socials.map(social => (
              <IconButton
                key={social.id}
                sx={{
                  '&.MuiButtonBase-root:hover svg path': {
                    fill: '#9000FF'
                  }
                }}
                LinkComponent={Link}
                href={social.link}
                target='_blank'
              >
                {social.svg}
              </IconButton>
            ))}
          </Box>
          <Box
            sx={{
              display: 'flex',
              fontSize: '14px',
              zIndex: '3',
              '@media (max-width:768px)': {
                flexDirection: 'column',
                maxHeight: '150px',
                flexWrap: 'wrap'
              }
            }}
          >
            {pages?.map(page => {
              const isActiveItem = !hash ? router.pathname === page.link : `#${hash}` === page.link

              return (
                <Box
                  key={page.id}
                  sx={{
                    marginRight: '30px',
                    '&.MuiBox-root:last-child': {
                      marginRight: '0'
                    },
                    '&.MuiBox-root a': {
                      color: 'inherit',
                      textDecoration: 'initial',
                      borderBottom: isActiveItem ? '1px solid #5c5c5c' : 'initial'
                    },
                    '&.MuiBox-root a:hover': {
                      color: '#fff',
                      borderBottom: isActiveItem ? '1px solid #fff' : 'initial'
                    }
                  }}
                >
                  <Link href={page.link}>{formatMessage({ id: page.title }).toUpperCase()}</Link>
                </Box>
              )
            })}
          </Box>
        </Box>
      </Container>
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          pt: '5px',
          '&.MuiContainer-root': { pl: 0, pr: 0 },
          '@media (max-width:1224px)': {
            '&.MuiContainer-root': {
              flexDirection: 'column-reverse',
              pl: 4,
              pr: 4
            }
          }
        }}
      >
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center',
            fontSize: '15px',
            '&.MuiTypography-root': { color: 'inherit' }
          }}
        >
          {formatMessage({ id: 'copyright' }, { year: `2023-${currentYear}` })}
          {'. '}
          {formatMessage({ id: 'all-right-reserved' })}
          {'.'}
          <span style={{ marginLeft: 8, opacity: 0.5 }}>
            {'v.'}
            {process.env.NEXT_PUBLIC_APP_VERSION}
          </span>
        </Typography>
        <List
          sx={{
            display: 'flex',
            gap: '50px',
            '& .MuiListItem-root:nth-of-type(2) .MuiBox-root': { display: 'none' },
            '& .MuiListItem-root:nth-of-type(2) .MuiTypography-root': { mr: 0 },
            '@media (max-width:1024px)': {
              gap: '0px',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              overflow: 'hidden',
              '& .MuiListItem-root:nth-of-type(3)': { width: 'unset' }
            },
            '@media (max-width: 768px)': {
              flexDirection: 'column'
            }
          }}
        >
          <FooterBottomList menuList={menuList} />
        </List>
      </Container>
    </Box>
  )
}

export default Footer
