// ** Next Imports
import Link from 'next/link'

// ** MUI Imports
import { ListItem, Box, Typography } from '@mui/material'

// ** Source code Imports
import { useIntl } from 'react-intl'
import { MenuItem } from 'src/layouts/default-layout/opts'

interface IProps {
  menuList: MenuItem[]
}

const FooterBottomList = ({ menuList }: IProps) => {
  const { formatMessage } = useIntl()

  return (
    <>
      {menuList.map(item => (
        <ListItem key={item.id} sx={{ p: 0, width: 'unset', position: 'relative' }}>
          <Typography
            component={Link}
            href={item.link}
            target={item.target}
            sx={{
              '&:hover': {
                color: '#fff'
              },
              width: 'max-content',
              fontSize: 15,
              color: 'inherit',
              textDecoration: 'none',
              '@media (max-width:768px)': {
                fontSize: 13
              }
            }}
          >
            {formatMessage({ id: item.text })}
          </Typography>
          <Box
            sx={{
              position: 'absolute',
              right: '-25px',
              width: '1px',
              height: '12px',
              background: '#5c5c5c',
              '@media (max-width:1024px)': {
                right: '-33%'
              },
              '@media (max-width:700px)': {
                display: 'none'
              }
            }}
          />
        </ListItem>
      ))}
    </>
  )
}

export default FooterBottomList
