// ** MUI Imports
import { Box } from '@mui/material'

// ** Next Imports
import Link from 'next/link'
import { useRouter } from 'next/router'

// ** Hooks Imports
import { useIntl } from 'react-intl'

// ** Source code Imports
import { PageItem } from 'src/layouts/default-layout/opts'

interface IProps {
  pages: PageItem[]
}

const MenuList = ({ pages }: IProps) => {
  // ** Hooks
  const { formatMessage } = useIntl()
  const router = useRouter()
  const hash = router.asPath.split('#')[1]

  return (
    <>
      {pages?.map(page => {
        const isActiveItem = !hash ? router.pathname === page.link : `#${hash}` === page.link

        return (
          <Box
            key={page.id}
            sx={{
              '&.MuiBox-root a': {
                color: '#fff',
                textDecoration: 'initial',
                borderBottom: isActiveItem ? '1px solid #fff' : 'initial'
              },
              '&.MuiBox-root a:hover': {
                color: '#9000FF',
                borderBottom: isActiveItem ? '1px solid #9000FF' : 'initial'
              },
              '@media (max-width:1024px)': { display: 'none' }
            }}
          >
            <Link href={page.link}>{formatMessage({ id: page.title }).toUpperCase()}</Link>
          </Box>
        )
      })}
    </>
  )
}
export default MenuList
