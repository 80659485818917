// ** React Imports
import React, { useEffect } from 'react'

// ** Next Imports
import Head from 'next/head'
import { Router, useRouter } from 'next/router'
import type { NextPage } from 'next'
import type { AppProps } from 'next/app'
import { GoogleTagManager } from '@next/third-parties/google'

// ** MUI Imports
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

// ** Loader Import
import NProgress from 'nprogress'

// ** Emotion Imports
import { CacheProvider } from '@emotion/react'
import type { EmotionCache } from '@emotion/cache'

// ** Config Imports
import themeConfig from 'src/configs/themeConfig'
import featureFlagConfig from 'src/configs/feature-flag'

// ** Third Party Import
import { YandexMetricaProvider } from 'next-yandex-metrica'
import { Toaster } from 'react-hot-toast'

import ThemeComponent from 'src/@core/theme/ThemeComponent'
import WindowWrapper from 'src/@core/components/window-wrapper'

// ** Contexts
import { HistoryProvider } from 'src/context/HistoryContext'
import { SettingsConsumer, SettingsProvider } from 'src/@core/context/settingsContext'

// ** Modules Imports
import 'dayjs/locale/ru'
import { IntlProvider } from 'react-intl'
import ResizeObserver from 'resize-observer-polyfill'
import { FlagProvider } from '@unleash/proxy-client-react'

// ** Styled Components
import ReactHotToast from 'src/@core/styles/libs/react-hot-toast'

// ** Utils Imports
import { createEmotionCache } from 'src/@core/utils/create-emotion-cache'

// ** Prismjs Styles
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-jsx'
import 'prismjs/components/prism-tsx'

// ** React Perfect Scrollbar Style
import 'react-perfect-scrollbar/dist/css/styles.css'

import 'src/iconify-bundle/icons-bundle-react'

// ** Locales
import ru from 'src/locale/ru.json'
import en from 'src/locale/en.json'

// ** Source code Imports
import DefaultLayout from 'src/layouts/default-layout'
import CookiesPolicy from 'src/layouts/components/cookies-policy'

// ** Global css styles
import '../../styles/globals.css'
import '../assets/style.css'

// ** Extend App Props with Emotion
type ExtendedAppProps = AppProps & {
  Component: NextPage
  emotionCache: EmotionCache
}

const clientSideEmotionCache = createEmotionCache()

// ** Pace Loader
if (themeConfig.routingLoader) {
  Router.events.on('routeChangeStart', () => {
    NProgress.start()
  })
  Router.events.on('routeChangeError', () => {
    NProgress.done()
  })
  Router.events.on('routeChangeComplete', () => {
    NProgress.done()
  })
}

const locales = { ru, enUS: en }

// ** Configure JSS & ClassName
const App = (props: ExtendedAppProps) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props

  // Variables
  const getLayout = Component.getLayout ?? (page => <DefaultLayout>{page}</DefaultLayout>)

  const setConfig = Component.setConfig ?? undefined

  const originHost = `https://${process.env.NEXT_PUBLIC_DOMAIN}`

  const router = useRouter()
  const _pathSliceLength = Math.min.apply(Math, [
    router.asPath.indexOf('?') > 0 ? router.asPath.indexOf('?') : router.asPath.length,
    router.asPath.indexOf('#') > 0 ? router.asPath.indexOf('#') : router.asPath.length
  ])
  const canonicalURL = originHost + router.asPath.substring(0, _pathSliceLength)

  useEffect(() => {
    if (window) window.ResizeObserver = ResizeObserver
  }, [])

  const metaTitle = `${themeConfig.templateName} — сервис, где музыка зарабатывает`
  const metaDescription =
    'Продвижение и дистрибьюция вашей музыки по 150+ сервисам. Продавай свои треки через дистрибьютора музыки Silk, специально для музыкантов и музыкальных лейблов, продюсеров и music менеджеров.'

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <title>{metaTitle}</title>
        <meta charSet='utf-8'/>
        <meta name='description' content={metaDescription}/>

        <meta itemProp='name' content={metaTitle}/>
        <meta itemProp='description' content={metaDescription}/>
        <meta itemProp='image' content={`${originHost}/images/og-icon-300x300.jpg`}/>

        <meta property='og:url' content={originHost}/>
        <meta property='og:type' content='website'/>
        <meta property='og:site_name' content={process.env.NEXT_PUBLIC_DOMAIN}/>
        <meta property='og:title' content={metaTitle}/>
        <meta property='og:description' content={metaDescription}/>
        <meta property='og:image' content={`${originHost}/images/og-image.jpg`}/>
        <meta property='og:image:secure_url' content={`${originHost}/images/og-image.jpg`}/>
        <meta property="og:image:width" content="968"/>
        <meta property="og:image:height" content="504"/>
        <meta property='og:image:type' content='image/jpg'/>

        <meta name='twitter:card' content='summary_large_image'/>
        <meta name='twitter:title' content={metaTitle}/>
        <meta name='twitter:description' content={metaDescription}/>
        <meta name='twitter:image' content={`${originHost}/images/og-icon-500x500.jpg`}/>

        <meta name='keywords' content={`${themeConfig.templateName}, Publishing Platform, Admin, Platform`}/>
        <meta name='viewport' content='initial-scale=1, width=device-width'/>

        <link rel='apple-touch-icon' sizes='60x60' href={`${originHost}/images/apple-touch-icon-60x60.jpg`}/>
        <link rel='apple-touch-icon' sizes='72x72' href={`${originHost}/images/apple-touch-icon-72x72.jpg`}/>
        <link rel='apple-touch-icon' sizes='120x120' href={`${originHost}/images/apple-touch-icon-120x120.jpg`}/>
        <link rel='apple-touch-icon' sizes='152x152' href={`${originHost}/images/apple-touch-icon-152x152.jpg`}/>
        <link rel='apple-touch-icon' sizes='180x180' href={`${originHost}/images/apple-touch-icon-180x180.jpg`}/>
        <link rel='icon' href='/images/favicon.png' type='image/png' />
        <link rel='shortcut icon' href={`${originHost}/images/favicon.svg`} type='image/svg+xml' />
        <link rel='canonical' href={canonicalURL}/>
      </Head>
      <YandexMetricaProvider
        tagID={94806853}
        initParameters={{
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true,
          webvisor: true
      }}>
        <FlagProvider config={featureFlagConfig}>
          <LocalizationProvider adapterLocale='ru' dateAdapter={AdapterDayjs}>
            <IntlProvider messages={locales['ru']} locale='ru' defaultLocale='ru'>
              <HistoryProvider>
                <SettingsProvider {...(setConfig ? { pageSettings: setConfig() } : {})}>
                  <SettingsConsumer>
                    {({ settings }) => {
                      return (
                        <ThemeComponent settings={settings}>
                          <WindowWrapper>{getLayout(<Component {...pageProps} />)}</WindowWrapper>
                          <ReactHotToast>
                            <Toaster position={settings.toastPosition} toastOptions={{ className: 'react-hot-toast' }} />
                          </ReactHotToast>
                          <CookiesPolicy />
                        </ThemeComponent>
                      )
                    }}
                  </SettingsConsumer>
                </SettingsProvider>
              </HistoryProvider>
            </IntlProvider>
          </LocalizationProvider>
        </FlagProvider>
        <GoogleTagManager gtmId='GTM-MT7T97JR' />
      </YandexMetricaProvider>
    </CacheProvider>
  )
}

export default App
